import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { GovernanceQuery } from '../../graphql-types';
import { parseLocale } from '../locale';
import SEO from '../components/seo';
import { GovernanceTemplate } from '../templates/governance.template';
import { mapFragmentToGovernanceProps } from '../data-mappers/governance.mapper';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query Governance($language: String) {
    cms {
      governance(locale: $language) {
        title
        metas {
          id
          description
        }
        governance_header {
          ...AlignedMarkdown
        }
        team_header {
          ...AlignedMarkdown
        }
        content_image {
          ...ScrollableContentMedia
        }
        board_priorities {
          board_member {
            id
          }
        }
      }
      boardMembers(locale: $language) {
        ...BoardMember
      }
    }
  }
`;

const Governance: React.FC<{ data: GovernanceQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const { cms } = data;
  const governanceProps = mapFragmentToGovernanceProps(data, locale);

  useBreadcrumbs({
    pageTitle: cms.governance?.title ?? '',
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'governance' },
      { locale: 'fr', slug: 'gouvernance' },
    ],
  });

  return (
    <>
      <SEO lang={locale} title={cms.governance?.title ?? ''} description={cms.governance?.metas?.description ?? ''} />
      <GovernanceTemplate {...governanceProps} />
    </>
  );
};

export default Governance;
