import React from 'react';
import { useTheme } from '@emotion/react';
import { TextContentWithMedia, TextContentWithMediaProps } from '../components/molecules/text-content-with-media';
import { TeamCards, TeamCardsProps } from '../components/organisms/team';
import { Section } from '../components/molecules/section';
import styled from '@emotion/styled';
import { AlignedMarkdown, AlignedMarkdownProps } from '../components/atoms/aligned-markdown';

const StyledSection = styled(Section)`
  margin: 70px 0 0 0;
`;

const TextSection = styled(Section)`
  padding: 70px 0 50px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.desktop}) {
    padding: 70px 20px 50px 20px;
  }
`;

export type GovernanceProps = {
  governanceHeader?: AlignedMarkdownProps;
  teamHeader?: AlignedMarkdownProps;
  contentImage?: TextContentWithMediaProps;
  boardMembers?: TeamCardsProps;
};

export const GovernanceTemplate: React.FC<GovernanceProps> = props => {
  const theme = useTheme();

  return (
    <>
      {props.governanceHeader && (
        <TextSection>
          <AlignedMarkdown {...props.governanceHeader} align="center" />
        </TextSection>
      )}

      {props.teamHeader && (
        <TextSection background={theme.color.palette['gray-lighten-3']}>
          <AlignedMarkdown {...props.teamHeader} align="center" />
        </TextSection>
      )}

      {props.boardMembers && <TeamCards {...props.boardMembers} />}

      {props.contentImage && (
        <StyledSection>
          <TextContentWithMedia {...props.contentImage} scroll />
        </StyledSection>
      )}
    </>
  );
};
