import { BoardMemberFragment, GovernanceQuery, ScrollableContentMediaFragment } from '../../graphql-types';
import { TeamCardProps, TeamCardsProps } from '../components/organisms/team';
import { AvailableLocales } from '../locale';
import { GovernanceProps } from '../templates/governance.template';
import { mapFragmentToImageProps } from './image.mapper';
import { mapFragmentToMediaProps } from './media.mapper';
import { TextContentWithMediaProps } from '../components/molecules/text-content-with-media';
import { isPresent, safeApply } from './utils';
import { mapFragmentToAlignedMarkdownProps } from './markdown.mapper';

function mapFragmentToScrollableTextImageProps(
  fragment: ScrollableContentMediaFragment,
  locale: AvailableLocales
): TextContentWithMediaProps {
  return {
    title: fragment.title ?? '',
    markdown: fragment.text ?? '',
    media: mapFragmentToMediaProps(fragment.media, fragment.youtube_embed_id ?? '', locale, fragment.mobile_media),
  };
}

type PrioritizedBoardMember = BoardMemberFragment & { priority: number };

function byMemberPriority(m1: PrioritizedBoardMember, m2: PrioritizedBoardMember) {
  if (!m1.lastname) return 1;
  if (!m2.lastname) return -1;

  return m1.priority === m2.priority
    ? // sort by name if same priority (alphabetical order - ascending)
      m1.lastname < m2.lastname
      ? -1
      : 1
    : // sort by priority - descending
    m1.priority < m2.priority
    ? 1
    : -1;
}

type BoardPriorities = NonNullable<GovernanceQuery['cms']['governance']>['board_priorities'];

function mapFragmentToTeamCardsProps(
  boardMembers: BoardMemberFragment[],
  boardPriorities: BoardPriorities,
  locale: AvailableLocales
): TeamCardsProps {
  const membersWithPriority = boardMembers.map<PrioritizedBoardMember>(member => ({
    // Default priority for everyone is 0
    priority: 0,
    ...member,
  }));

  boardPriorities?.forEach((member, index) => {
    // Set priority based on index in the list (lowest index => highest priority)
    const memberToUpdate = membersWithPriority.find(m => m.id === member?.board_member?.id);

    if (memberToUpdate) {
      memberToUpdate.priority = boardPriorities.length - index;
    }
  });

  const teamCardsArray = membersWithPriority
    .sort(byMemberPriority)
    .map(member => mapFragmentToTeamCardProps(member, locale));

  return {
    teamCards: teamCardsArray,
  };
}

function mapFragmentToTeamCardProps(fragment: BoardMemberFragment, locale: AvailableLocales): TeamCardProps {
  return {
    category: fragment.category,
    title: `${fragment.firstname} ${fragment.lastname}`,
    text: fragment.position ?? '',
    description: {
      descriptionTitle: fragment.category,
      descriptionText: fragment.description ?? '',
    },
    image: mapFragmentToImageProps(fragment.image, locale),
  };
}

export function mapFragmentToGovernanceProps(content: GovernanceQuery, locale: AvailableLocales): GovernanceProps {
  const {
    cms: { governance, boardMembers },
  } = content;

  return {
    governanceHeader: safeApply(value => mapFragmentToAlignedMarkdownProps(value), governance?.governance_header),
    teamHeader: safeApply(value => mapFragmentToAlignedMarkdownProps(value), governance?.team_header),
    contentImage: safeApply(value => mapFragmentToScrollableTextImageProps(value, locale), governance?.content_image),
    boardMembers:
      boardMembers && governance?.board_priorities
        ? mapFragmentToTeamCardsProps(boardMembers.filter(isPresent), governance.board_priorities, locale)
        : undefined,
  };
}
