import React, { useState } from 'react';
import styled from '@emotion/styled';
// import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';

import BurgerMenuCrossIcon from '../../assets/burger-menu-cross.svg';
import { Image, ImageProps } from '../atoms/image';
import { AlignedMarkdown } from '../atoms/aligned-markdown';
import { useBreakpoints } from '../../hooks/useBreakpoints';

export type TeamCardsProps = {
  teamCards: TeamCardProps[];
};

export type TeamCardProps = {
  image?: ImageProps;
  category: MemberCategory;
  title?: string;
  text?: string;
  description?: {
    descriptionTitle?: string;
    descriptionText?: string;
  };
};

export type MemberCategory = 'founding_members' | 'external_experts' | 'staff_representatives';

// const List = styled.ul`
//   display: flex;
//   padding: 30px 10px 30px 25px;
//   margin: 0;
//   list-style-type: none;
//   background-color: ${({ theme }) => theme.color.palette['gray-lighten-3']};
//   border-top: 1px solid ${({ theme }) => theme.color.palette['gray-lighten-1']};
//   border-bottom: 1px solid ${({ theme }) => theme.color.palette['gray-lighten-1']};

//   @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
//     padding: 30px 10px 30px 15px;
//   }
// `;

// type ListItemProps = { isActive: boolean };
// const ListItem = styled.button<ListItemProps>`
//   padding-left: 5px;
//   color: ${({ isActive, theme }) => (isActive ? theme.color.palette['base-blue'] : theme.color.palette['pure-black'])};
//   min-width: 40px;
//   max-width: 100px;
//   white-space: pre-wrap;
//   overflow: hidden;
//   text-overflow: ellipsis;

//   @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
//     padding-left: 35px;
//     max-width: none;
//   }
// `;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.palette['gray-lighten-2']};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 100%;
    max-width: 100%;
    padding: 0 40px 40px 40px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px auto 0 auto;

  & + & {
    margin: 5px auto 0 auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
`;

const TeamCardWrapper = styled.div`
  width: 85%;
  margin: 0 auto 45px auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    max-width: 33%;
    padding: 0 40px;
    margin: 0;
  }
`;

const StyledImage = styled(Image)<ImageProps>`
  width: 100%;
`;

type TitleProps = { isActive: boolean };
const Title = styled.div<TitleProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.font.size.h3};
  font-family: ${({ theme }) => theme.font.family.header};
  color: ${({ isActive, theme }) => (isActive ? theme.color.palette['base-blue'] : theme.color.palette['pure-black'])};
`;

const DisplayedDescriptionWrapper = styled.div`
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin: 40px;
  }
`;

const DisplayedDescription = styled.div`
  width: 85%;
  margin: 0 auto 50px auto;
  padding: 5%;
  background-color: ${({ theme }) => theme.color.palette['pure-white']};

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: 100%;
    margin: 0;
    padding: 50px 100px;
  }
`;

// const DescriptionTitle = styled.div`
//   margin-bottom: 30px;
//   font-size: ${({ theme }) => theme.font.size.h3};
//   line-height: ${({ theme }) => theme.font.lineHeight.h3};
//   font-family: ${({ theme }) => theme.font.family.header};
// `;

const BurgerMenuCrossWrap = styled.i`
  svg {
    display: block;
  }

  position: relative;
  width: 16px;
  right: -95%;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    right: -100%;
    top: -20px;
  }
`;

function chunk<T>(array: T[]): T[][] {
  const res = [];
  let line = [];
  for (let i = 0; i < array.length; i++) {
    if (line.length === 3) {
      res.push(line);
      line = [];
    }
    line.push(array[i]);
  }

  if (line.length > 0) {
    res.push(line);
  }
  return res;
}

export const TeamCards: React.FC<TeamCardsProps> = props => {
  const { isDesktop } = useBreakpoints();

  // Staff categories to be removed as a menu and as Description Title in staff cards
  // const [filtered, setFilter] = useState<MemberCategory | null>(null);
  const [displayedDescription, setDescription] = useState<null | {
    description: TeamCardProps['description'];
    lineIndex: number;
    cardIndex: number;
  }>(null);

  // const intl = useIntl();

  // const categories = [
  //   {
  //     filter: null,
  //     label: intl.formatMessage({ id: 'all' }),
  //   },
  //   {
  //     filter: 'founding_members',
  //     label: intl.formatMessage({ id: 'founding_members' }),
  //   },
  //   {
  //     filter: 'external_experts',
  //     label: intl.formatMessage({ id: 'external_experts' }),
  //   },
  //   {
  //     filter: 'staff_representatives',
  //     label: intl.formatMessage({ id: 'staff_representatives' }),
  //   },
  // ];

  // const displayedCards = !filtered ? props.teamCards : props.teamCards.filter(card => filtered === card.category);
  const lines = chunk(props.teamCards);

  return (
    <>
      {/* <List>
        {categories.map(category => {
          return (
            <ListItem
              isActive={filtered === category.filter}
              key={category.label}
              onClick={() => {
                setFilter(category.filter as MemberCategory);
                setDescription(null);
              }}
            >
              {category.label}
            </ListItem>
          );
        })}
      </List> */}

      <TeamWrapper {...props}>
        {lines.map((line, lineIndex) => {
          const displayedDescriptionLine = displayedDescription && displayedDescription.lineIndex === lineIndex;
          return (
            <React.Fragment key={lineIndex}>
              <StyledDiv>
                {line.map((card, cardIndex) => {
                  const displayedDescriptionCard =
                    displayedDescription &&
                    displayedDescription.lineIndex === lineIndex &&
                    displayedDescription.cardIndex === cardIndex;
                  return (
                    <>
                      <TeamCardWrapper key={cardIndex}>
                        <div
                          style={{ cursor: 'pointer' }}
                          role="button"
                          tabIndex={0}
                          onKeyPress={() =>
                            setDescription({
                              description: card.description,
                              lineIndex,
                              cardIndex,
                            })
                          }
                          onClick={() =>
                            setDescription({
                              description: card.description,
                              lineIndex,
                              cardIndex,
                            })
                          }
                        >
                          <StyledImage {...card.image} />
                        </div>
                        <Title isActive={displayedDescriptionCard ?? false}>{card.title}</Title>
                        <AlignedMarkdown align="left" markdown={card.text} />
                      </TeamCardWrapper>
                      {!isDesktop && displayedDescriptionCard && displayedDescription ? (
                        <DisplayedDescriptionWrapper>
                          <DisplayedDescription>
                            <BurgerMenuCrossWrap>
                              <BurgerMenuCrossIcon onClick={() => setDescription(null)} />
                            </BurgerMenuCrossWrap>
                            {/* <DescriptionTitle>
                              {intl.formatMessage({ id: displayedDescription?.description?.descriptionTitle })}
                            </DescriptionTitle> */}
                            <AlignedMarkdown
                              align="left"
                              markdown={displayedDescription?.description?.descriptionText}
                            />
                          </DisplayedDescription>
                        </DisplayedDescriptionWrapper>
                      ) : null}
                    </>
                  );
                })}
              </StyledDiv>
              {isDesktop && displayedDescriptionLine && displayedDescription ? (
                <DisplayedDescriptionWrapper>
                  <DisplayedDescription>
                    <BurgerMenuCrossWrap>
                      <BurgerMenuCrossIcon onClick={() => setDescription(null)} />
                    </BurgerMenuCrossWrap>
                    {/* <DescriptionTitle>
                      {intl.formatMessage({ id: displayedDescription?.description?.descriptionTitle })}
                    </DescriptionTitle> */}
                    <AlignedMarkdown align="left" markdown={displayedDescription?.description?.descriptionText} />
                  </DisplayedDescription>
                </DisplayedDescriptionWrapper>
              ) : null}
            </React.Fragment>
          );
        })}
      </TeamWrapper>
    </>
  );
};

export const BoardMemberFragment = graphql`
  fragment BoardMember on cms_BoardMember {
    id
    firstname
    lastname
    category
    position
    description
    image {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  }
`;
